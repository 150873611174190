<template>
  <div class="world-interactor">
    <div>
      <p class="world-interactor-title">World Interactor</p>
    </div>

    <select class="area-select" v-model="selectedArea">
      <option disabled value="">Select an Area</option>
      <option v-for="area in areas" :key="area.area" :value="area">{{ area.area }}</option>
    </select>

    <select class="area-source-select" v-model="selectedSource" v-if="selectedArea">
      <option disabled value="">Select a Source</option>
      <option v-for="source in selectedArea.dataSources" :key="source.source" :value="source">{{ source.source }}</option>
    </select>

    <button @click="generateSheet">Query</button>
  </div>
</template>

<script>
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

export default {
  name: 'WorldInteractor',
  props: {
    playerName: {
      type: String,
      required: false,
    }
  },
  data() {
    return {
      areas: [],
      selectedArea: null,
      selectedSource: null,
      responseSheet: null
    };
  },
  methods: {
    getWorldAreaImage(selectedArea) {
      if(selectedArea) {
        return("creature.jpeg");        
      } else {
        return("creature.jpeg");
      }
    },
    generateUniqueId() {
      return uuidv4();
    },
    generateSheet() {
        var baseUrl = this.db_url; 
        var endpoint = this.selectedSource.endpoint;
        var metadataEndpoint = endpoint + "/metadata";
        var newSheet = {};

        console.log("creating new sheet with endpoint: ", endpoint)
        console.log("creating new sheet with metadata endpoint: ", metadataEndpoint)
        
        console.log("requesting sheet metadata from endpoint: ", `${baseUrl}${metadataEndpoint}`)
        axios.get(baseUrl + metadataEndpoint)
        .then(response => {  
            newSheet.id = this.generateUniqueId();
            newSheet.title = `${response.data.params['playerName'] ? this.playerName + "'s " : ""}${this.selectedSource.source}`;

            let typeName = endpoint.split('/')[1];
            typeName = typeName.charAt(0).toUpperCase() + typeName.slice(1);
            newSheet.type = typeName + " Data";

            newSheet.endpoint = baseUrl + endpoint;

            // Initialize params as an empty object
            newSheet.params = {};

            // For each key in response.data.params
            for (let key in response.data.params) {
                // If this[key] exists
                if (this[key] !== undefined) {
                    // Add key-value pair to newSheet.params
                    newSheet.params[key] = this[key];
                } else {
                    // If the key is required but does not exist in this
                    if (response.data.params[key]["required"]) {
                        // Return error and exit
                        throw new Error("Lacking required query parameter: " + key);
                    }
                }
            }

            newSheet.icon = "icon.png";
            newSheet.metadata = response.data;

            console.log("new sheet with metadata: ", newSheet.metadata)
            console.log("based on metadata, set query params to: ", newSheet.params)

            this.$emit('query-response', newSheet);
        })
        .catch(error => {
            console.error(error); // handle error
        });
      }
    },
    created() {
        axios.get(this.db_url + '/meta/areas').then(response => {
          this.areas = response.data;
          this.selectedArea = this.areas[0]
          this.selectedSource = this.selectedArea.dataSources[0]
        });
    },
    watch: {
      selectedArea(newArea) {
        this.selectedSource = newArea.dataSources[0];
      }
    },
    emits: ['query-response']
  }
</script>

<style>
.world-interactor {
  position: relative;
  display: flex;
  left: 150px;
  width: 15%;
  padding: 10px;
  margin-top: 30px;
  justify-content: center;
  flex-direction: column;
  background: white;
  box-shadow: 0 0 0 3px black
}

.world-interactor-title {
  text-align: center;
  font-size: 12;
  font-weight: bold;
  margin-top: 0px;
  margin-bottom: 10px;
}

.area-select {
  margin: 10px;
}

.area-source-select {
  margin: 10px;
}

.question-button {
position: absolute;
bottom: 20px;
right: 20px;
background-color: #444;
color: #fff;
border: none;
border-radius: 5px;
padding: 10px 20px;
font-size: 16px;
cursor: pointer;
}

.creature-image {
position: absolute;
bottom: 20px;
left: 20px;
width: 100px;
height: auto;
}
</style>